import type {
  StatusResult,
  StatusResultBean,
} from "common-components-nuxt-types/common/statusResult";

export function fromBean(statusResult: StatusResultBean): StatusResult {
  return {
    code: statusResult.code,
    type: statusResult.type._name,
    title: statusResult.title,
    description: statusResult.description,
  };
}

export function toBean(statusResult: StatusResult): StatusResultBean {
  return {
    code: statusResult.code,
    type: { _name: statusResult.type },
    title: statusResult.title,
    description: statusResult.description,
  };
}

export function isStatusResult(
  statusResult: unknown,
): statusResult is StatusResult {
  return (
    (statusResult as StatusResult) !== undefined &&
    (statusResult as StatusResult) !== null &&
    (statusResult as StatusResult).code !== undefined &&
    (statusResult as StatusResult).type !== undefined
  );
}

export function isDataStatusResult(
  data: unknown,
): data is { data: StatusResult } {
  return (
    (data as { data: StatusResult }).data !== undefined &&
    (data as { data: StatusResult }).data !== null &&
    (data as { data: StatusResult }).data.code !== undefined &&
    (data as { data: StatusResult }).data.type !== undefined
  );
}
